import Form from './Form/Form';


function Devis() {
    return (
        <>
        <Form />
        </>
    )
}

export default Devis;