import React from "react";
import Fade from "react-reveal/Fade";
import photo6 from "../../../public/Images/PHOTO 6.jpg";

function SolutionSection() {
  return (
    <section>
      <Fade left>
        <div style={{ gridColumnGap: "120px" , paddingTop: '100px',  margin: "50px"}} className="objectifMontre">
          <div>
        <h1 style={{  alignItems: "center" }}>
              <b>
                Une solution inédite pour couvrir vos montres en cas de Vol{" "}
              </b>
            </h1>
            <p>
              Grâce à nos partenariats exclusifs avec les plus grandes
              compagnies d’assurance européennes et mondiales, nous vous
              proposons une solution unique pour couvrir vos montres en toutes
              situations. Qu’elle soit stockée chez vous dans un coffre, ou
              portée tous les jours à votre poignet, notre contrat vous couvre
              en cas de vol et tentative de vol. Si vos enfants ou votre
              conjoint sont amenés à porter régulièrement une montre vous
              appartenant, eux aussi sont assurés en cas de vol. Qu’il s’agisse
              d’un vol par ruse, par agression, à main armée lors d’un
              cambriolage ou d’un braquage à votre domicile, vous êtes assurés
              d’obtenir réparation financière, en fonction de la valeur de votre
              garde-temps .
            </p>
            </div>
 
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
alt=""
width="100%"
src={photo6}
/>
         

          </div>
        </div>
      </Fade>
    </section>
  );
}

export default SolutionSection;
