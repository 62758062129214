import React from "react";
import Fade from "react-reveal/Fade";
import photo2 from "../../../public/Images/PHOTO 2.jpg";

function SecondSection() {
  return (
    <section>
      <Fade right>
        <div style={{ gridColumnGap: "120px" }} className="objectif sectionArticle">
          <img alt="" src={photo2} />
          <div className="subTextSection">
            <h1 style={{ fontSize: "30px" }}>
              <b>Une couverture complète</b>
            </h1>
            <br/>
            <p>
              Au-delà du choc émotionnel lié au vol de ces biens, le préjudice
              financier peut être élevé. Après une agression, vous pouvez
              également avoir subi une atteinte corporelle importante. Ainsi, il
              est primordial d’assurer vos biens précieux à leur juste valeur,
              mais également de vous assurer personnellement en cas d’atteinte
              physique, afin d’obtenir une prise en charge rapide et un
              dédommagement pécuniaire.
            </p>
            <br/>
          </div>
        </div>
      </Fade>
    </section>
  );
}

export default SecondSection;
