import React from "react";
import Fade from "react-reveal/Fade";
import photo13 from "../../../public/Images/PHOTO 13.jpg";

function PriseEnCharge() {
  return (
    <section>
      <Fade left>
        <div
          style={{
            gridColumnGap: "120px",
            paddingTop: "100px",
            margin: "50px",
          }}
          className="objectifMontre"
        >
          <div>
            <h1 style={{ alignItems: "center" }}>
              <b>La prise en charge de vos effets personnels</b>
            </h1>
            <p>
              Au delà de la protection de votre sac à main, notre solution vous
              offre également la couverture de vos effets personnels de type
              portefeuille, papiers d’identité, ou téléphone portable.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img alt="" width="100%" src={photo13} />
          </div>
        </div>
      </Fade>
    </section>
  );
}

export default PriseEnCharge;
