import FirstSection from "./ObjectifSection";
import Devis from "./Devis";
import SecondSection from "./CouvertureSection";
import ThirdSection from "./GarantiesSection";
import SolutionSection from "./SolutionSection";

function Page() {
  return (
    <>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <SolutionSection/>
      <Devis/>
    </>
  );
}

export default Page;
