import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
require("dotenv").config();

function DevisForm() {
  const [inputValues, setInputValue] = useState({ type: "Sac" });
  const [open, setOpen] = React.useState(false);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };

  // console.log("POSTAlCOde", PostalCode);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    // if (name === "city") {
    //    setInputValue({ ...inputValues, ["city"]: value })
    // }
    console.log("name", name);
  }

  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  function handleSubmit(event) {
    event.preventDefault();
    document.getElementById("continueInformation").disabled = true;
    // const regex = /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/;
    // console.log("regex.test(inputValues.mail)", regex.test(inputValues.mail), inputValues.mail)
    // if (!regex.test(inputValues.mail)) {
    //   document.getElementById("email_status_warning").style.display = "block";
    //   return;
    // }
    axios
      .post(
        process.env.REACT_APP_URL_MAILER + "/api/devis/sendEmail",
        inputValues,
        axiosConfig
      )
      .then((reponse) => {
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
      });
    handleOpen();
  }

  function getCity(postal) {
    let result = "";
    axios.get("/codepostal.json").then((reponse) => {
      let correspondingPostalCode = FilterOnPostalCodeAndReturnListOfCities(
        reponse,
        postal
      );

      setCities(correspondingPostalCode);
    });

    return result;
  }

  function FilterOnPostalCodeAndReturnListOfCities(reponse, postal) {
    return reponse.data
      .filter(function (elem) {
        return elem.code === postal;
      })
      .map(function (elem) {
        return elem["city"];
      });
  }

  function handleChangeCity(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, ["postal"]: value });
    getCity(value);
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Votre demande a bien été transmise, un conseiller vous contactera sous
          peu.
        </Alert>
      </Snackbar>
      <Fade top>
        <section className="sectionForm">
          <form style={{ width: "114vh" }} onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="items" id="second">
                <p className="label-input">Prénom</p>
                <input
                  required
                  onChange={(e) => handleChange(e)}
                  value={inputValues.first_name}
                  maxLength="41"
                  className="form-control"
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder=""
                />
                <span id="first_name_warning" className="warning">
                  Prénom invalide
                </span>
              </div>
              <div className="items" id="third">
                <p className="label-input">Nom</p>
                <input
                  required
                  pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.last_name}
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="form-control"
                  placeholder=""
                />
                <span id="last_name_warning" className="warning">
                  Nom invalide
                </span>
              </div>
        
              <div className="items" id="seven">
                <p className="label-input">Email</p>
                <input required
                  className="form-control"
                  type="text"
                  name="mail"
                  id="mail"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.mail}
                />
                <span id="email_status_warning" className="warning">
                  Veuillez renseigner un email .
                </span>
              </div>
              <div className="items" id="seven">
                <p className="label-input">Téléphone</p>
                <input
                  required
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength="10"
                  minLength="8"
                  className="form-control"
                  name="phone"
                  id="phone"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.phone}
                />
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner un téléphone valide.
                </span>
              </div>

              <div className="items" id="seven">
                <p className="label-input">Adresse</p>
                <input
                  required
                  className="form-control"
                  type="text"
                  name="adress"
                  id="adress"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.adress}
                />
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner une adresse valide
                </span>
              </div>
              <div></div>
              <div className="items" id="seven">
                <p className="label-input">Code postal</p>
                <input
                  required
                  className="form-control"
                  type="text"
                  name="city"
                  id="city"
                  onChange={(e) => handleChangeCity(e)}
                  value={inputValues.postal}
                />
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner une ville valide.
                </span>
              </div>
              <div className="items" id="seven">
                <p className="label-input">Ville</p>
                {/* <input required
                  className="form-control"
                  type="text"
                  name="city"
                  id="city"
                  // onChange={(e) => handleChange(e)}
                  value={inputValues.city}
                /> */}
                <select
                  required
                  className="form-control"
                  type="text"
                  name="city"
                  id="city"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.city}
                >
                  <option value="">Veuillez selectionner</option>
                  {cities &&
                    cities.map((cities) => (
                      <option value={cities}>{cities}</option>
                    ))}
                </select>
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner une ville valide.
                </span>
              </div>

              <div className="items" id="seven">
                <p className="label-input">Type</p>
                <select
                  className="form-control"
                  type="text"
                  name="type"
                  id="type"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.type}
                >
                  <option value="">Veuillez selectionner</option>
                  <option value="sac">Sac</option>
                  <option value="bijoux">Bijoux</option>
                  <option value="monstres">Montres</option>
                </select>
              </div>

              <div className="items" id="seven">
                <p className="label-input">Marque</p>
                <input
                  required
                  className="form-control"
                  type="text"
                  name="brand"
                  id="brand"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.brand}
                />
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner une marque.
                </span>
              </div>
              <div className="items" id="seven">
                <p className="label-input">Date de naissance</p>
                <input required
                  className="form-control"
                  type="date"
                  name="birthdate"
                  id="birthdate"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.birthdate}
                />
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner une date de naissance valide.
                </span>
              </div>
              <div className="items" id="seven">
                <p className="label-input">Valeur</p>
                <input
                  required
                  className="form-control"
                  type="number"
                  name="value"
                  maxLength={5}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  id="value"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.value}
                />
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner une valeur .
                </span>
              </div>
              {/* <div className="items" id="seven">
                <p className="label-input">Email</p>
                <input
                  required
                  className="form-control"
                  type="text"
                  name="mail"
                  id="mail"
                  onChange={(e) => handleChange(e)}
                  value={inputValues.mail}
                />
                <span id="fam_status_warning" className="warning">
                  Veuillez renseigner un email .
                </span>
              </div> */}
            </div>
            <br />
            <button id="continueInformation" className="btn-primary">
              Continuer
            </button>
          </form>
        </section>
      </Fade>
    </>
  );
}
export default DevisForm;
