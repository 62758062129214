import React from "react";
import Fade from "react-reveal/Fade";
import photo3 from "../../../public/Images/PHOTO 3.jpg";

function ThirdSection() {
  return (
    <section>
      <Fade left>
        <div style={{ gridColumnGap: "120px" }} className="objectif sectionArticle">
        <img
            alt=""
            src={photo3}
          />
          <div className="subTextSection">
            <h1 style={{ fontSize: "30px" }}>
              <b>Des garanties exclusives </b>
            </h1>
            <br/>
            <p>
              Montres, bijoux, biens précieux… Autant d’objets qui souvent
              traversent le temps, avec la vocation d’être transmis de
              générations en générations. Que ces objets de valeurs soient
              anciens ou plus récents, ils ont toujours attiré la convoitise et
              sont aujourd’hui une cible privilégiée : vols à la tire, par
              effraction ou avec braquage au domicile, les risques qu’ils vous
              soient dérobés sont importants. Les agressions avec vol à
              l’arrachée, de plus en plus nombreuses, sont l’exemple de la
              recrudescence récente de la délinquance visant vos colliers,
              bracelets, bagues ou vos montres.
            </p>
          </div>
        </div>
      </Fade>
    </section>
  );
}

export default ThirdSection;
