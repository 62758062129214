import React from 'react';
import ObjectifSection from './ObjectifSection'
import SolutionSection from './SolutionSection'
import EvenementsSection from './EvenementsSection'
import GarantiesServiceSection from './Garanties&Service';
import AssuranceMontre from './AssuranceMontre';

function Montres() {
    return (
        <>
        <ObjectifSection/>
        <SolutionSection/>
        <EvenementsSection/>
        <GarantiesServiceSection/>
        <AssuranceMontre/>
        </>
    )
}

export default Montres;