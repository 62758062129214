import React from "react";
import BaguesNColliers from "./BaguesNColliers";
import BouclesNBracelets from "./BouclesNBracelets";

function Bijoux() {
  return (
    <>
      <BaguesNColliers />
      <BouclesNBracelets />
    </>
  );
}

export default Bijoux;
