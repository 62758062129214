import React from "react";
import Fade from "react-reveal/Fade";

function AssuranceMontre() {
  return (
    <section style={{ background: "#949189", padding: "117px" }}>
      <Fade down>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: "50px",
          }}
        >
          <center>
            <p>
              <b>Assurez votre montre</b>
            </p>
          </center>
          <h1>
            <b>
              La seule chose que vous regretterez, c’est de ne pas l’avoir fait
              plus tôt.{" "}
            </b>
          </h1>
          <center>
            <br />
            <a href="/devis">
              <button style={{ width: "200px" }} className="buttonDevis">
                Obtenir un devis
              </button>
            </a>
          </center>
        </div>
      </Fade>
    </section>
  );
}

export default AssuranceMontre;
