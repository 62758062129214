import React from 'react';
import bouclesOrreilles from "../../../public/bijoux3.jpg";
import Bracelets from "../../../public/bijoux4.jpg";
import Fade from "react-reveal/Fade";

function BaguesNColliers() {
    return (
        <section className="sessionBijoux" >
        <Fade up>
          <div className="bouclesOrreilles">
            <center>
              <img className="bijouxImage" src={bouclesOrreilles} />
              <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
                Boucles d’Oreille{" "}
              </h1>
              <figcaption className="bouclesOrreillesText" style={{width: "66%"}}>
                Accessoire de mode par excellence, les boucles d’oreille sont au
                même titre que les autres bijoux des biens assurables dans notre
                solution. Qu’elles soient constituées de métaux précieux ou
                fantaisie, soyez couverts en cas de vol à domicile, mais aussi en
                cas de vol avec agression ayant lieu dans la rue.{" "}
              </figcaption>
            </center>
          </div>
          <div className="Bracelets">
            <center>
              <img  className="bijouxImage" src={Bracelets} />
              <h1 style={{ marginTop: "20px", marginBottom: "10px" }}>
                Bracelets
              </h1>
              <figcaption style={{width: "66%"}}>
                Vos bracelets en Or ou en Argent, gourmettes ou autres bijoux de
                poignet en métaux précieux sont des cibles faciles pour les
                voleurs à l’arrachée. Certains de ces bijoux peuvent avoir une
                grande valeur sentimentale, mais aussi une grande valeur
                pécuniaire. C’est pourquoi nous vous proposons une couverture vol
                étendue à l’agression pour tous vos bracelets, précieux ou
                fantaisie.
              </figcaption>
            </center>
          </div>
        </Fade>
      </section>
    )
}

export default BaguesNColliers;