import React from 'react';


function Devis() {

    return (
        <div className="devis">
            <h1><b>La meilleure solution pour assurer vos objets précieux</b></h1>
            <br/>
           <a  href="/devis"><button className="buttonDevis">Obtenir un devis</button></a>
        </div>
    )

}

export default Devis;