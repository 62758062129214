import React from "react";
import Fade from "react-reveal/Fade";
import photo12 from "../../../public/Images/PHOTO 12.jpg";

function Protection() {
  return (
    <section>
      <Fade left>
        <div
          style={{
            gridColumnGap: "120px",
            paddingTop: "100px",
            margin: "50px",
          }}
          className="objectifMontre"
        >
          <div>
            <h1 style={{ alignItems: "center" }}>
              <b>Une protection en cas de vol</b>
            </h1>
            <p>
              Que votre sac à main soit dérobé à votre domicile, ou qu’il vous
              soit arraché dans la rue, nous avons la solution pour l’assurer en
              cas de vol.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img alt="" width="100%" src={photo12} />
          </div>
        </div>
      </Fade>
    </section>
  );
}

export default Protection;
