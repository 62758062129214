import React, { useEffect } from "react";
import Logo from "../../public/logo/prestige-bijoux.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
function NavBar() {
  useEffect(() => {
    let menuIcon = document.querySelector(".menuIcon");
    let nav = document.querySelector(".overlay-menu");

    menuIcon.addEventListener("click", () => {
      if (nav.style.transform != "translateX(0%)") {
        nav.style.transform = "translateX(0%)";
        nav.style.transition = "transform 0.2s ease-out";
      } else {
        nav.style.transform = "translateX(-100%)";
        nav.style.transition = "transform 0.2s ease-out";
      }
    });
  }, []);

  return (
    <>
      <nav id="navbar" class="">
        <div class="nav-wrapper">
          <div class="logo">
            <a href="/">
              <img alt="" className="Logo" width="46px" src={Logo} />
            </a>
          </div>

          <ul id="menu">
            <li>
              <a className="button-devis" href="/devis">
                Obtenir un devis
              </a>
            </li>
            <li>
              <a href="/montres">Montres</a>
            </li>
            <li>
              <a  href="/bijoux">Bijoux</a>
            </li>
            <li>
              <a href="/sacsamain">Sacs à Main</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </nav>
      <div class="menuIcon">
      <FontAwesomeIcon icon={faBars} />
      </div>
      <div class="overlay-menu">
        <ul id="menu">
        <li>
              <a className="button-devis" href="/devis">
                Obtenir un devis
              </a>
            </li>
          <li>
            <a href="/montres">Montres</a>
          </li>
          <li>
            <a  href="/bijoux">Bijoux</a>
          </li>
          <li>
              <a href="/sacsamain">Sacs à Main</a>
            </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default NavBar;
{
  /* <header className="header">
        <div className="topnav" id="myTopnav">
          <div className="containerNav">
            <div className="logo">
              <a className="itemNav title-navbar" href="/">
                {" "}
                <img alt="" className="Logo" width="50%" src={Logo} />
              </a>
            </div>

            <div className="items-navbar">
              <ul className="list-items-navbar">
                <li>
                  <a className="button-devis" href="/devis">
                    Obtenir un devis
                  </a>
                </li>
                <li id="one">
                  <a className="itemNav" href="/montres">
                    Montres
                  </a>{" "}
                </li>
                <li id="two">
                  <a className="itemNav" href="/bijoux">
                    Bijoux
                  </a>
                </li>
                <li id="three">
                  <a className="itemNav" href="/sacsamain">
                    Sacs à main
                  </a>
                </li>
                <li id="four">
                  <a className="itemNav" href="/contact">
                    Contact
                  </a>
                  <hr />
                </li>
              </ul>
            </div>

            <div
              onClick={showElementNav}
              id="navbar-phone"
              className="navbar-phone"
            >
              <label className="menu-button-container" htmlFor="menu-toggle">
                <div className="menu-button"></div>
              </label>
              <ul id="menuElement" className="menu">
                <li>
                  <a
                    style={{ width: "100%", textAlign: "center" }}
                    href="/montres"
                  >
                    Montres
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    style={{ width: "100%", textAlign: "center" }}
                    href="/bijoux"
                  >
                    Bijoux
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    style={{ width: "100%", textAlign: "center" }}
                    href="/sacsamain"
                  >
                    Sac à main
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    style={{ width: "100%", textAlign: "center" }}
                    href="/contact"
                  >
                    Contact
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a
                    style={{ width: "100%", textAlign: "center" }}
                    href="/devis"
                  >
                    Obtenir un devis
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header> */
}
