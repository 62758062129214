import React from "react";
import Fade from "react-reveal/Fade";
import ObjectifGlobal1 from "../../../public/Images/objectif_global1.JPG"
function FirstSection() {
  return (
    <>
      <section>
        <Fade left>
        <h1 style={{ fontSize: "37px", marginBottom: "50px" }}>
       <b> L’assurance de vos biens de valeurs, montres et <br /> bijoux, partout
        et en toutes circonstances.{" "}</b>  
      </h1>
      <div className="objectif sectionArticle">
          <div style={{ gridColumnGap: "120px" }} className="objectif">
            <img
              alt=""
              src={ObjectifGlobal1}
            />
          </div>
          <div className="subTextSection">
              <h1 style={{ fontSize: "30px" }}>
                <b>Notre Objectif : vous offrir une protection globale</b>
              </h1>
              <br/>
              <p>
                Montres, bijoux, biens précieux… Autant d’objets qui souvent
                traversent le temps, avec la vocation d’être transmis de
                générations en générations. Que ces objets de valeurs soient
                anciens ou plus récents, ils ont toujours attiré la convoitise
                et sont aujourd’hui une cible privilégiée : vols à la tire, par
                effraction ou avec braquage au domicile, les risques qu’ils vous
                soient dérobés sont importants. Les agressions avec vol à
                l’arrachée, de plus en plus nombreuses, sont l’exemple de la
                recrudescence récente de la délinquance visant vos colliers,
                bracelets, bagues ou vos montres.
              </p>
              </div>
            </div>
        </Fade>
      </section>
    </>
  );
}

export default FirstSection;
