import "./App.css";
import "./CSS/Navbar.css";
import "./CSS/Home.css";
import "./CSS/Form.css";
import "./CSS/Footer.css";
import "./CSS/Devis.css";
import "./CSS/GoogleMap.css";
import "./CSS/Montres.css";
import "./CSS/Bijoux.css";
import "./CSS/SacMain.css";
import "./CSS/Privacy.css";

import Footer from "./Compononent/Footer/Footer";
import NavBar from "./Compononent/NavBar/Navbar";
import Router from "./Router/router.js";
require('dotenv').config();

function App() {
  return (
    <div className="App">
      <NavBar />
      <Router />
      <Footer />
    </div>
  );
}

export default App;
