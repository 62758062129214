import React from "react";
import bagues from "../../../public/bijoux1.jpg";
import colliers from "../../../public/bijoux2.jpg";
import Fade from "react-reveal/Fade";

function BouclesNBracelets() {
  return (
    <section className="sessionBijoux">
      <Fade up>
        <div className="bouclesOrreilles">
          <center>
            <img className="bijouxImage" src={bagues} />
            <h1 className="titleBijouxImage">Bagues</h1>
            <figcaption style={{ width: "66%"}}>
              Intemporelles et traversant le temps, les bagues précieuses sont
              souvent transmises de générations en générations, et sont souvent
              le symbole de toute une famille. Que ces bagues soient en métal
              précieux comme l’or et l’argent, ou qu’elles soient plutôt
              fantaisie, notre solution vous assure une indemnisation à leur
              juste valeur, en cas de vol mais aussi en cas de bris accidentel.
            </figcaption>
          </center>
        </div>
        <div className="Bracelets">
          <center>
            <img className="bijouxImage" src={colliers} />
            <h1
              className="titleBijouxImage"
              style={{ marginTop: "20px", marginBottom: "10px" }}
            >
              Colliers
            </h1>
            <figcaption style={{ width: "66%" }}>
              Que vos colliers soient en Or, en Argent, ou autre métal précieux,
              nous avons la solution pour les assurer. La couverture des
              colliers est étendue au vol par agression, ce qui signifie qu’en
              cas de vol à l’arrachée, vous serez indemnisée. Sautoirs,
              pendentifs, médaillons, ras du cous ou colliers fantaisie sont
              également couverts.
            </figcaption>
          </center>
        </div>
      </Fade>
    </section>
  );
}

export default BouclesNBracelets;
