import React from "react";
import Fade from "react-reveal/Fade";
import sacs from "../../../public/Images/PHOTO 11.jpg";


function AssuranceSacs() {
  return (
    <>
    <section style={{ padding: "116px 40px 21px 51px" }}>
      <Fade up>
        <div style={{ gridColumnGap: "120px", marginTop: "100px" }} className="objectifMontre">
          <div>
            <figure>
              <img className="imgSacaMain" style={{borderRadius: "50px"}} alt="" src={sacs} />
            </figure>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "30px", alignItems: "center" }}>
              <b>L'assurance de vos sacs à Mains et bagages, en toutes circonstances</b>
            </h1>
            <a href="/devis">
            <button style={{width: "200px"}} className="buttonEnSavoirPlus">Obtenir un Devis </button>
            </a>

          </div>
        </div>
      </Fade>
    </section>
    </>
  );
}

export default AssuranceSacs;
