function Confidentialite() {
  return (
    <div className="privacy">
      <section>
        <h1>Mentions Légales </h1>
        <p>
          Nom de la société : DALPHIN GALISE ASSURANCES <br />
          Numéro de Siret : 84493574200016 <br />
          Immatriculation ORIAS : N°13001646 - 18007287 – 18007288 <br />
          Nom du responsable : Clement Dalphin <br />
          Telephone : 04 42 99 24 40 <br />
          Adresse de la société : 22 Avenue Du Marechal de Lattre de Tassigny -
          13100 Aix-en-Provence <br />
          Adresse du siège social de la société : 22 Avenue Du Marechal de
          Lattre de Tassigny - 13100 Aix-en-Provence <br />
          EDITEUR DE LA PUBLICATION : SPEC DALPHIN GALISE  <br />
          DIRECTEUR DE LA PUBLICATION : SPEC DALPHIN GALISE  <br />
          RESPONSABLE DE LA REDACTION : SPEC DALPHIN GALISE  <br />
          HEBERGEUR : OVH <br />
          {/* WEBMASTER : Eloise Boyer <br /> */}
        </p>
      </section>
    </div>
  );
}

export default Confidentialite;
