import React from "react";
import Fade from "react-reveal/Fade";

function GarantiesServiceSection() {
  return (
    <section>
      <Fade down>
        <div
          style={{ gridColumnGap: "120px", paddingTop: "100px" ,   margin: "50px"}}
          className="objectifMontre"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1>
            <b>Des garanties complètes</b>
            </h1>
          </div>
          <h1 style={{ alignItems: "center" }}>
            <b>Un service personnalisé et confidentiel</b>
          </h1>
        </div>
      </Fade>
    </section>
  );
}

export default GarantiesServiceSection;
