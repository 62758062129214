import React from "react";
import AssuranceSacs from "./AssuranceSacs";
import PriseEnCharge from "./PriseEnCharge";
import Protection from "./Protection";

function SacAMain() {
  return (
    <>
    <AssuranceSacs/>
    <Protection/>
    <PriseEnCharge/>
    </>
  );
}

export default SacAMain;
