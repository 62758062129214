import React, { useState } from "react";
import GoogleMapReact from "./GoogleMap/googlemap.js";
import axios from "axios";

function Contact() {
  const [inputValues, setInputValue] = useState({});
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    // if (name === "city") {
    //    setInputValue({ ...inputValues, ["city"]: value })
    // }
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("contact", inputValues)
      axios
        .post(process.env.REACT_APP_URL_MAILER + "/api/devis/contact", inputValues, axiosConfig)
        .then((reponse) => {
        });
  }

  return (
    <>
      <div className="mapGoogle">
        <div style={{ margin: "0 auto", color: "white", width: "85%" }}>
        <h1>
            <b>Contact</b>
          </h1>
          <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px", }}>
            <div className="items" id="second">
              <p className="label-input">Prénom</p>
              <input
                required
                onChange={(e) => handleChange(e)}
                value={inputValues.first_name}
                maxLength="41"
                className="form-control"
                type="text"
                id="first_name"
                name="first_name"
                placeholder=""
              />
              <span id="first_name_warning" className="warning">
                Prénom invalide
              </span>
            </div>
            <div className="items" id="third">
              <p className="label-input">Nom</p>
              <input
                required
                pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                onChange={(e) => handleChange(e)}
                value={inputValues.last_name}
                type="text"
                name="last_name"
                id="last_name"
                className="form-control"
                placeholder=""
              />
              <span id="last_name_warning" className="warning">
                Nom invalide
              </span>
            </div>
            
            <div className="items" id="second">
              <p className="label-input">Email</p>
              <input
                required
                onChange={(e) => handleChange(e)}
                value={inputValues.mail}
                maxLength="41"
                className="form-control"
                type="text"
                id="mail"
                name="mail"
                placeholder=""
              />
              <span id="email_warning" className="warning">
                Email invalide
              </span>
            </div>
            <div className="items" id="second">
              <p className="label-input">Téléphone</p>
              <input
                required
                onChange={(e) => handleChange(e)}
                value={inputValues.phone}
                maxLength="41"
                className="form-control"
                type="text"
                id="phone"
                name="phone"
                placeholder=""
              />
              <span id="phone_warning" className="warning">
                Téléphone invalide
              </span>
            </div>
          </div>
          <br/>
          <button  onClick={(e) => handleSubmit(e)} id="continueInformation" className="btn-primary">
              Continuer
            </button>
          <br/>
          <br/>
          <br/>
          <p
            className="contact-text"
            style={{ lineHeight: "34px", textAlign: "center", cursor: "context-menu" }}
          >
            Timeless Assurance <br />
            AGENCE DALPHIN-GALISE <br /> Assurances, Santé & Services Financiers{" "}
            <br /> 22 avenue du Maréchal de Lattre de Tassigny
            <br /> 13100 Aix en Provence <br />
            Tél : 04.42.99.24.40 <br /> Port : 06.75.41.85.28 <br /> N°
            ORIAS : 13001646 - 18007287 – 18007288 <br /> ACPR: 4 Place de
            Budapest, CS 92459, 75436 Paris Cedex 09. <br /> En cas de
            réclamation, et conformément à la règlementation en vigueur, vous
            pouvez vous adresser au Service Réclamations de notre cabinet.
          </p>
        </div>
        {/* <div>
          <GoogleMapReact />
        </div> */}
      </div>
    </>
  );
}

export default Contact;
