import React from "react";
import Logo from "../../public/logo/prestige-bijoux.png";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <>

      <div class="footer-dark">
        <footer>
          <div class="container">
            <div class="row">
              <div >
                <h3>Timeless Assurance</h3>
                <ul>
                  <li><a href="/confidentialite">Politique de confidentialité</a></li>
                  <li><a  href="/mention">Mentions Légales</a></li>
                </ul>
              </div>
            </div>
            <p class="copyright">Timeless Assurance © 2023</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;


<div style={{ display: "flex" }} id="footer">
<img
  width="10%"
  className="logo"
  alt=""
  src={Logo}
/>
<div style={{display: "flex", flexDirection: "row"}}>
<a href="/confidentialite">Politique de confidentialité</a>
<a style={{marginLeft: "10px"}} href="/mention">Mentions Légales</a>
</div>
</div>