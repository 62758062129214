import React from "react";
import Fade from "react-reveal/Fade";

function EvenementsSection() {
  return (
    <section>
      <Fade right>
        <div
          style={{ gridColumnGap: "120px", paddingTop: "100px" ,  margin: "50px"}}
          className="objectifMontre"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p>
              Au delà de la garantie vol et vandalisme, notre solution couvre
              vos montres en cas de dommages subis à cause d’un incendie,
              explosion, dégat des eaux, catastrophe naturelle, ou tout autre
              évènement accidentel incluant notamment la casse ou le bris. En
              cas de dommage, si la montre est réparable, une indemnisation vous
              est versée afin de couvrir le coût des réparations et du
              remplacement de certaines pièces si nécessaire.
            </p>
          </div>
          <h1 style={{ alignItems: "center" }}>
            <b>Mais aussi en cas d’autres évènements accidentels imprévus </b>
          </h1>
        </div>
      </Fade>
    </section>
  );
}

export default EvenementsSection;
