import React from "react";
import Fade from "react-reveal/Fade";
import photo5 from "../../../public/Images/PHOTO 5.jpg";


function ObjectifSection() {
  return (
    <section style={{ padding: "116px 40px 21px 51px", marginTop: "110px" }}>
      <Fade right>
        <div style={{ gridColumnGap: "120px" }} className="objectifMontre">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "30px", alignItems: "center" }}>
              <b>Notre objectif : Protéger vos montres. </b>
            </h1>
            <p>
              Que vous soyez amateur de montres de luxe ou collectionneur de
              pièces Vintage, nous avons la solution pour assurer votre montre à
              sa juste valeur.
            </p>
            <a href="/devis">
              <button style={{ width: "200px" }} className="buttonEnSavoirPlus">
                Obtenir un Devis{" "}
              </button>
            </a>
          </div>
          <div className="montre">
            <figure>
              <img alt="" src={photo5} />
            </figure>
          </div>
        </div>
      </Fade>
    </section>
  );
}

export default ObjectifSection;
