function Confidentialite() {
  return (
    <div className="privacy">
      <section>
     <h1> POLITIQUE DE CONFIDENTIALITE TIMELESS ASSURANCE </h1>
   <p>TIMELESSE ASSURANCE s’engage à collecter et utiliser vos données dans le respect de la 
      règlementation applicable relative à la protection des données  
      personnelles, notamment du Règlement européen relatif à la protection des
      personnes physiques à l’égard du traitement des données à caractère
      personnel (RGPD), et à en assurer la sécurité et la confidentialité. La
      présente charte (voir ci après) vise à vous informer des raisons pour
      lesquelles nous sommes amenés à traiter vos données personnelles, de la
      manière dont nous le faisons et de vos droits et réclamations en la
      matière. La Charte de confidentialité couvre tant les traitements que nous
      réalisons dans le cadre de l’offre des produits et services proposés par
      notre intermédiaire. Afin d’en appréhender facilement la lecture, ci
      dessous quelques précisions relatives à la terminologie employée dans
      notre charte de confidentialité en matière de protection des données. </p>
      <h2>Qu’est-ce qu’une donnée personnelle ? </h2>
      <p>C’est une information ou donnée
      permettant directement ou indirectement d’identifier une personne physique
      (notamment avec les nom, prénom, numéro de téléphone, adresse email,
      adresse IP,etc…)</p>
      <h2> Qu’est-ce qu’une donnée sensible ? </h2> 
      <p>C’est une
      information ou donnée révélant l'origine raciale ou ethnique, les opinions
      politiques, les convictions religieuses ou philosophiques ou encore
      l'appartenance syndicale, ainsi que les données génétiques, les données
      biométriques, aux fins d'identifier une personne physique de manière
      unique. Ce sont aussi les données concernant la santé ou concernant la vie
      sexuelle ou l'orientation sexuelle d'une personne physique, ainsi que les
      données relatives aux condamnations pénales et aux infractions.</p>
      <h2> Qu’est-ce que le traitement des données ? </h2> 
      <p>Un traitement de données est
      toute opération ou ensemble d’opérations effectuées ou non à l’aide de
      procédés automatisés et portant sur des données personnelles, incluant
      notamment la collecte, l’enregistrement, l’organisation, la conservation,
      l’adaptation, la modification, l’extraction, la consultation,
      l’utilisation, la communication par transmission, diffusion ou toute autre
      forme de mise à disposition, le rapprochement ou l’interconnexion, la
      limitation, l’effacement ou la destruction. Charte de confidentialité <br/>
      Pourquoi collectons-nous vos données personnelles ? <br/> Afin de procéder à
      la passation, la gestion et l’exécution des contrats d’assurance et de
      tout autre type de contrat que vous avez souscrit auprès de l’un de nos
      partenaires via nos différents canaux de distribution. (ex : contrat de
      prévoyance) Cela inclut notamment l’étude de vos besoins afin de proposer
      des contrats adaptés à votre situation ainsi que l’appréciation du risque
      et son suivi au cours du contrat. Cela inclut également les opérations
      nécessaires à la mise en œuvre de vos garanties et prestations, le
      traitement de ces opérations et la mise à jour de la connaissance client.
      Dans le cadre de nos activités, nous pouvons par ailleurs être amenés à
      obtenir des informations issues du Registre National d’Identification des
      Personnes Physiques (RNIPP) afin de rechercher tant des assurés que des
      bénéficiaires de contrats d’assurance sur la vie. <br/> Afin de contribuer à
      la lutte contre la fraude et la surveillance du portefeuille. Nous ne
      mettons en œuvre aucun traitement entièrement automatisé qui aurait pour
      conséquence la résiliation d’un contrat ou la suppression de garanties.
      Toute suspicion de fraude fait l’objet d’une étude au cas par cas par
      notre cellule spécialisée. <br/> Afin d’organiser la gestion de votre espace
      personnel via notre site Internet à travers la gestion des connexions mais
      aussi la mise en œuvre des mesures appropriées pour assurer la sécurité de
      votre compte. <br/> Afin de mener des opérations relatives à la gestion
      commerciale ou à la prospection commerciale Ces opérations incluent
      notamment celles liées à la fidélisation, l’élaboration de statistiques
      commerciales, la mise à jour de la connaissance client ou encore, avec
      votre consentement lorsque celui-ci est requis, l’envoi d’offres
      promotionnelles et commerciales susceptibles de vous intéresser, en
      fonction de votre profil. <br/> Afin de participer à la lutte contre le
      blanchiment de capitaux et le financement du terrorisme, et gels des
      avoirs ; <br/> Afin de de remplir l’exécution des dispositions légales,
      réglementaires et administratives en vigueur conformément à la
      réglementation applicable; <br/> Afin de procéder à la gestion de vos
      réclamations et autres demandes diverses (comme une demande d’information
      relative à nos produits et services par exemple) <br/> Afin de procéder à la
      gestion de vos demandes de droit d’accès, de rectification, d’opposition
      et de tout autre droit relatif à l’utilisation de vos données
      personnelles. Lorsque nous sommes amenés à collecter et à traiter des
      données vous concernant pour des finalités particulières, c’est-à-dire à
      des fins autres que celles décrites ci-dessus, nous vous en informerons
      dans une notice d’information spécifique. <br/> Qui est le responsable du
      traitement de vos données personnelles ? DALPHIN GALISE Assurances – SPFL
      Agent général ALLIANZ Entreprise régie par le Code des Assurances Adresse
      et Siège Social : 122 Avenue Du Marechal de Lattre de Tassigny – 13100 AIX
      EN PROVENCE SIREN N°844935742- SIRET N°84493574200016 <br/> Avec qui sommes
      nous amenés à partager vos données ? Dans la stricte limite des
      traitements effectués pour les finalités énoncées précédemment, nous
      sommes amenés à communiquer certaines de vos données : <br/> aux personnels
      habilités des entités du groupe ALLIANZ , dans le cadre de l’exercice de
      leurs missions habituelles (exemple : gestion et exécution des contrats.)
      <br/> aux délégataires de gestion et intermédiaires d’assurance ; <br/> aux
      co-assureurs, réassureurs, ainsi qu’aux organismes professionnels et fonds
      de garanties ; <br/> aux personnes intéressées au contrat (exemple : ayants
      droit, victimes, témoins, tiers intéressés à l’exécution du contrat, etc.)
      <br/> aux organismes sociaux et d’assurances complémentaires, notamment dans
      le cadre de la souscription et gestion des contrats ; <br/> aux personnes
      intervenant au contrat (ex. avocats, médecins conseils, experts
      d’assurance, etc.) <br/> aux partenaires/prestataires – nous pouvons par
      exemple transmettre certaines informations à nos partenaires afin de vous
      faire bénéficier de services et de prestations permettant la gestion d’une
      situation ou d’un évènement précis (par exemple : gestion d’un sinistre,
      etc.) <br/> aux sous-traitants – par exemple, nous pouvons être amenés à
      confier certaines opérations techniques liées à la maintenance de nos
      systèmes informatiques à des entreprises externes ; <br/> aux personnes
      habilitées au titre des tiers autorisés (ex. autorités de contrôle,
      commissaires aux comptes, auditeurs, etc.). <br/> Quelles sont les
      justifications légales relatives au traitement de vos données personnelles
      ? Les justifications légales sur la base desquelles nous pouvons traiter
      vos données personnelles sont : <br/> le consentement ; <br/> la nécessité
      d’exécuter un contrat ou des mesures pré-contractuelles pour les finalités
      mises en œuvre par notre groupe ; <br/> le respect d’une obligation légale ; <br/>
      la sauvegarde des intérêts vitaux de la personne concernée ; <br/> l’intérêt
      légitime du responsable de traitement.   <br/> Combien de temps
      conservons-nous vos données ? Vos données personnelles sont conservées
      pendant la durée nécessaire à la réalisation des finalités décrites dans
      la présente Charte de confidentialité. Elles sont ensuite archivées
      conformément aux obligations légales ou réglementaires, ou afin de
      permettre au groupe ALLIANZ ou de nos différents partenaires d’établir la
      preuve d’un droit ou d’un contrat (selon délais légaux applicables en
      matière de prescription). <br/> Quelles sont vos préférences en matière de
      prospection commerciale ? <br/> Nous sommes amenés à réaliser des actions de
      communication dans le cadre de notre relation avec vous, afin de vous
      proposer des produits et services adaptés à vos besoins. <br/> Si vous y
      consentez (hormis les cas dans lesquels la règlementation en vigueur nous
      y autorise), nous sommes susceptibles de vous adresser nos offres
      commerciales par email et/ou par SMS. Sauf opposition de votre part, nous
      sommes également susceptibles de vous contacter par téléphone ou de vous
      adresser nos offres par voie postale. <br/> Vous pouvez à tout moment retirer
      votre consentement et/ou vous opposer à recevoir des communications
      commerciales de notre part en nous contactant par mail ou par téléphone. <br/>
      Nous pouvons également être amenés à traiter les données dont nous
      disposons vous concernant afin de vous adresser des offres commerciales
      basées sur l’analyse de votre profil, susceptibles de vous intéresser.
      Conformément à la règlementation applicable relative à la protection des
      données personnelles, vous avez le droit de vous opposer à ce type de
      profilage. Dans ce cas, nous ne traiterons plus vos données à cette fin. <br/>
      Vous avez par ailleurs la possibilité de vous inscrire sur la liste
      d’opposition au démarchage téléphonique « BLOCTEL » sur le site internet
      www.bloctel.gouv.fr. <br/> Quels sont vos droits ? Conformément à la
      règlementation applicable, vous disposez des droits ci-dessous
      relativement à l’utilisation de vos données personnelles. Il est à noter
      que ces droits peuvent être soumis à certaines conditions, limitations et
      exceptions légales. <br/> Droit d’opposition : vous pouvez vous opposer, pour
      des raisons tenant à votre situation particulière, au traitement de vos
      données personnelles. <br/> Droit à l’effacement : vous pouvez, dans certains
      cas, obtenir l’effacement des données personnelles vous concernant. <br/>
      Droit de rectification : vous avez le droit d’obtenir la rectification des
      données personnelles vous concernant qui sont inexactes, ainsi que de les
      faire compléter si nécessaire, en fonction du changement de votre
      situation. <br/> Droit d’accès : vous avez le droit d’obtenir la confirmation
      que des données personnelles vous concernant sont traitées, d’en obtenir
      une copie ainsi que certaines informations liées à leur traitement. <br/>
      nécessaires au regard des finalités pour lesquelles elles ont été
      collectées. <br/> Droit à la limitation du traitement : dans certaines
      circonstances, vous avez le droit d’obtenir la limitation du traitement de
      vos données personnelles. <br/> Droit à la portabilité de vos données : dans
      certains cas, vous pouvez demander à recevoir les données personnelles
      vous concernant que vous nous avez fournies ou, lorsque cela est possible,
      à ce que nous les transmettions directement à un autre responsable de
      traitement. <br/> Droit de retirer votre consentement : pour les traitements
      nécessitant un consentement de votre part, vous avez le droit de retirer à
      tout moment votre consentement. <br/> Droit de définir des directives
      relatives au sort de vos données personnelles post-mortem : vous avez le
      droit de définir des directives relatives à la conservation, à
      l'effacement et à la communication de vos données personnelles après votre
      décès. <br/> Vous disposez également du droit d’introduire une réclamation
      auprès de la Commission Nationale Informatique et Libertés (CNIL). Pour
      plus d’informations, nous vous invitons à consulter le site de la CNIL
      (Adresse : Commission Nationale de l’Informatique et des Libertés - 3
      Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07 / Téléphone : +33
      (0)1 53 73 22 22). <br/> Par ailleurs nous avons mis en place des mesures
      techniques et organisationnelles appropriées visant à assurer la sécurité
      de vos données et prévenir le risque lié à leur violation. Nous nous
      engageons à vous informer, en cas de violation de données vous concernant,
      et susceptible d’engendrer un risque élevé pour vos droits et libertés. <br/>
      Vos contacts pour toute réclamation relative à vos données personnelles
      Vous pouvez également formuler votre demande par mail à ou par courrier à
      l’adresse ALLIANZ- Informatique et Libertés – Case courrier S1805 – 1
      cours Michelet – CS 30051 - 92076 Paris La Défense Cedex. <br/> Mise à jour de
      la charte <br/> Cette charte est susceptible d’être révisée en fonction des
      évolutions législatives et réglementaires ou d’une modification des
      conditions de traitement des données personnelles. Nous vous invitons à la
      consulter régulièrement dans la rubrique « politique de confidentialité »
      de notre site.</p>
      </section>
    </div>
  );
}

export default Confidentialite;
