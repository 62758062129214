import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import jetest from "../../../../public/map-marker-icon.png";

const AnyReactComponent = ({ text }) => <div id="pointer" ><img className="pointerIcon" style={{width: "25px"}} src={jetest} /></div>;

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 43.53271085229372,
      lng: 5.436995858069802
    },
    zoom: 15,
  };


  render() {
    return (
      <div className='googleMapReact'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCnJHM0Hz7XU6ASX7gcPHQg8ORZKbG3Au8' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={43.53271085229372}
            lng={5.436995858069802}
            text="⚑"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;