import { BrowserRouter, Route, Routes, Navigate  } from "react-router-dom";
import React, { Suspense } from "react";
import Page from "../Compononent/Page/Home/home.js";
import Devis from "../Compononent/Page/Devis/devis.js";
import Contact from "../Compononent/Page/Contact/Contact.js";
import Montres from "../Compononent/Page/Montres/Montres.js";
import Bijoux from "../Compononent/Page/Bijoux/Bijoux.js";
import SacAMain from "../Compononent/Page/SacMain/SacMain.js";
import Privacy from "../Compononent/Privacy/Privacy.js";
import Mention from "../Compononent/Privacy/Mention.js";


function MissingRoute() {
  return < Navigate to={{pathname: '/'}} />
 }


export default function Router() {
  
  return (
    <>
      <Suspense
        fallback={
          <center>
            <h1 className="loadingTitle">Chargement</h1>
          </center>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route  path="/" element={<Page />} />
            <Route  path="/devis" element={<Devis />} />
            <Route  path="/contact" element={<Contact />} />
            <Route  path="/montres" element={<Montres />} />
            <Route  path="/bijoux" element={<Bijoux />} />
            <Route  path="/sacsamain" element={<SacAMain />} />
            <Route  path="/confidentialite" element={<Privacy />} />
            <Route  path="/mention" element={<Mention />} />
            <Route path="*" element={<MissingRoute/>} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}
